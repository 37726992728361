.CareList {
  position: relative;
  margin-top: 50px;
  padding-left: 38px;
  min-width: 850px;
  width: 63%;
  max-width: 1155px;
  height: 500px;
  font-family: "koPubWorld DotumB";
  z-index: -100;
}

.temp {
  position: absolute;
  width: 100%;
  height: 200px;
  z-index: 100;
}

.CareList .name {
  background: url("../assets/list.png") left no-repeat;
  background-size: 20px 20px;
  color: #e5857c;
  font-size: 24px;
  padding-left: 30px;
  margin-bottom: 18px;
  font-family: "Nanum Gothic Bold";
}
.CareList .tel {
  position: relative;
  margin-top: 125px;
  width: 850px;
  height: 250px;
  background: transparent url(../assets/icon_tel.png) 50px 72px no-repeat;
  background-size: 110px 110px;
  background-color: #f4f4f4;
}

.CareList .tel .ph_txt1 {
  padding-left: 57px;
  position: absolute;
  top: 65px;
  left: 120px;
  font-family: "koPubWorld DotumB";
  font-size: 3em;
  color: #333333;
  letter-spacing: -0.5px;
}
.CareList .tel .ph_txt2 {
  padding-left: 57px;
  font-weight: bold;
  position: absolute;
  top: 115px;
  left: 120px;
  font-size: 6em;
  line-height: 1em;
  letter-spacing: -1px;
  font-family: "Nanum Gothic Bold";
  color: #ea4336;
}

.CareList .list1 {
  background-color: #f4f4f4;
  padding: 10px;
  margin-bottom: 68px;
  z-index: -1;
}

.CareList .list1 .list2 {
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: pre-wrap;
  width: 80%;
}
.CareList .list1 .list2 p {
  font-family: "koPubWorld DotumB";
  padding-left: 10px;
  font-size: 17px;
  line-height: 28px;
}

/* .CareList .list1 .image {
  position: relative;
  float: right;
  top: -150px;

  width: 150px;
  height: 150px;
  margin-right: 10%;
} */
.CareList .list3 {
  margin-bottom: 50px;
  white-space: pre-wrap;
}

.CareList .list3 p {
  font-size: 15px;
  font-weight: bold;
  text-indent: -2.7em;
  margin-left: 2.7em;
  line-height: 25px;
}

.CareList .list3 span {
  font-size: 15px;
  font-weight: bold;

  color: #2679b2;
  margin-left: 5px;
  margin-right: 20px;
}

.CareList .list4 {
  width: 100%;
  height: 150px;
  background: url("../assets/care24.png") center top no-repeat;
  background-size: 100% 150px;
}

.CareList .list3 div p {
  color: #707070;
  font-weight: 0;
  text-indent: 0em;
  margin-left: 0em;
}

.temp {
  width: 850px;
}
