.SubTitle {
  position: relative;
  width: 230px;
  height: 180px;
  background: #e5857c url(../assets/title_bg.png) center center no-repeat;
  background-size: 150px 120px;
}
h2.k_title {
  width: 100%;
  height: 28px;
  padding-top: 36px;
  font-family: "Nanum Gothic Bold";
  color: #666;
  font-size: 24px;
  line-height: 26px;
  margin-left: 30px;
  color: #fff;
}
p.e_title {
  width: 175px;
  height: 25px;
  margin-top: 17px;
  margin-left: 30px;
  font-family: "koPubWorld DotumB";
  font-size: 12px;
  color: #fff;
  line-height: 1.5em;
}
