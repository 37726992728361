.quick {
  width: 100px;
  position: fixed;
  z-index: 900;
  top: 60%;
  left: 65%;
}

.q_q_menu ul {
  width: 100px;
  margin: 0 auto;
}
.q_q_menu ul li {
  text-align: center;
}

.q_q_menu ul li img {
  width: 100px;
  height: 100px;
}

@media (max-width: 320px) {
  .quick {
    left: 67%;
    width: 80px;
  }
  .q_q_menu ul li img {
    width: 80px;
    height: 80px;
  }
  .q_q_menu ul {
    width: 80px;
  }
}

@media (min-width: 320px) {
  .quick {
    left: 65%;
    width: 100px;
  }
  .q_q_menu ul li img {
    width: 100px;
    height: 100px;
  }
  .q_q_menu ul {
    width: 100px;
  }
}
@media (min-width: 375px) {
  .quick {
    left: 70%;
  }
}

@media (min-width: 425px) {
  .quick {
    left: 72%;
  }
}

@media (min-width: 768px) {
  .quick {
    left: 77%;
    width: 150px;
  }
  .q_q_menu ul li img {
    width: 150px;
    height: 150px;
  }
  .q_q_menu ul {
    width: 150px;
  }
}
