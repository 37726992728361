.Item {
  position: relative;
  float: left;
  border-top: 2px solid #e5857c;
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
}

.Item .title {
  float: left;
  width: 100%;
}

.Item .title h1 {
  font-family: "koPubWorld DotumM";
  font-size: 18px;
  color: #333333;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 5%;
  border-bottom: 1px solid #e5857c;
}

.Item .info {
  width: 100%;
  float: left;

  padding-top: 3%;
  padding-bottom: 3%;
}

.Item .info p {
  float: left;
  font-size: 14px;
  padding-top: 3%;

  color: #666666;
  font-family: "koPubWorld DotumM";
}

.Item .info p:nth-child(1) {
  font-family: "koPubWorld DotumB";
  width: 20%;
  padding-left: 5%;
}
.Item .info p:nth-child(2) {
  width: 70%;
  text-align: left;
  padding-left: 5%;
}
.Item .info p:nth-child(3) {
  font-family: "koPubWorld DotumB";
  width: 20%;
  padding-left: 5%;
}
.Item .info p:nth-child(4) {
  width: 70%;
  text-align: left;
  padding-left: 5%;
}

.Item .file {
  width: 100%;
  float: left;
  padding-bottom: 3%;
  border-bottom: 1px solid #c8c8c8;
}

.Item .file p {
  float: left;
  font-size: 14px;
  color: #666666;
  font-family: "koPubWorld DotumB";
}

.Item .file p:nth-child(1) {
  padding-left: 5%;

  width: 20%;
  padding-left: 5%;
}
.Item .file p:nth-child(2) {
  font-family: "koPubWorld DotumM";
  width: 70%;
  padding-left: 5%;
}

.Item .content {
  width: 95%;
  float: left;

  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
  border-bottom: 2px solid #e5857c;
  overflow: auto;
}

.Item .content div {
  margin-top: 5%;
  margin-left: 5%;
  margin-bottom: 5%;
}

.Item a {
  float: right;
  margin-top: 5%;
  font-family: "koPubWorld DotumB";
  font-size: 15px;
  background: url("../../assets/notice.png") center center no-repeat;
  background-size: 70% 80%;
  color: #fff;
  padding: 3%;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 20%;
}

.Item button {
  margin-left: 5%;
  float: left;
  font-size: 14px;
  font-family: "koPubWorld DotumM";
  color: #666666;
  height: 20px;
  padding-left: 7%;
  background: url(../../assets/attach.png) left center no-repeat;
  background-size: 20px;
}

@media (max-width: 320px) {
  .Item .file p:nth-child(1) {
    padding-left: 5%;

    width: 22%;
    padding-left: 5%;
  }
  .Item .file p:nth-child(2) {
    font-family: "koPubWorld DotumM";
    width: 70%;
    padding-left: 3%;
  }

  .Item button {
    background-size: 15px;
  }
}

@media (min-width: 375px) {
  .Item .file p:nth-child(1) {
    padding-left: 5%;

    width: 20%;
    padding-left: 5%;
  }
  .Item .file p:nth-child(2) {
    font-family: "koPubWorld DotumM";
    width: 70%;
    padding-left: 5%;
  }
}
