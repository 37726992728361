/* hidden */
caption,
legend,
.hidden,
#contents .hidden {
  position: absolute;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.Container {
  position: absolute;
  left: 0;
  top: 110px;
  z-index: 1000;
  height: 52px;
  background: linear-gradient(
    #e5857c 52px,
    rgba(245, 210, 206, 1) 50px,
    rgba(245, 210, 206, 1)
  );
  min-width: 1020px;
  width: 100%;
  overflow: hidden;
}

.Container ol,
.Container ol li,
.Container ul,
.Container ul li {
  list-style: none;
  margin-left: 0;
}

.Container.active {
  height: auto;
  background-color: #e5857c;
  border-bottom: 1px solid #066a1d;
} /*�ѿ����� ��ü �ڽ� ������*/
.Container a {
  color: #fff;
}

#navigation {
  position: relative;
  width: 1020px;
  margin: 0 auto;
  text-align: center;
}
.Container.active #navigation .depth2 {
  display: block;
}
#navigation .depth1 {
  position: absolute;

  font-size: 20px;
  width: 1020px;
  z-index: 1000;
}
#navigation .depth1 li {
  float: left;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  width: 250px;
}
#navigation .depth1 li.bd_line_left {
  background: url("../assets/bg_navi.png") left top repeat-y;
}
#navigation .depth1 li.bd_line_right {
  background: url("../assets/bg_navi.png") right top repeat-y;
}
#navigation .depth1 li.active {
  background-color: rgba(25, 123, 48, 0.2);
} /*����������*/
#navigation .depth1 li a {
  font-size: 22px;
  font-weight: bold;
  font-family: "Nanum Gothic Bold";
  display: block;

  width: 100%;
  height: 52px;
  line-height: 52px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
}

#navigation .depth1 li a:hover {
  color: #e5857c;
  text-decoration: none;
}

#navigation .depth2 {
  font-size: 17px;
  display: block;
  height: 320px;
  background-color: #f5d2ce;
  color: #666666;
  font-family: "Nanum Square Bold";
}
#navigation .depth2 li {
  float: none;
  background-color: none;
  border: 0;
}
#navigation .depth2 li a {
  font-size: 18px;
  height: 52px;
  line-height: 52px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  color: #666666;
}

#navigation .depth2 li a:hover {
  color: #e5857c;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.5);
}
