.PageNav {
  float: left;
  width: 100%;
  margin-top: 7%;
  text-align: center;
  margin-bottom: 15%;
}

.PageNav button {
  margin-left: 7%;
  color: #666666;
}

.PageNav button:first-child {
  margin-left: 0px;
}

.PageNav .page {
  font-size: 15px;

  font-family: "Nanum Square Bold";
}

.PageNav .navi img {
  width: 7px;
}

.PageNav .navi2 img {
  width: 10.5px;
}
