.Site {
  width: 100%;
  float: left;
}
.Site .subtitle {
  float: left;
  width: 100%;
  white-space: pre-line;
  margin-top: 15%;
  font-family: "Nanum Square Bold";
  color: #555555;
  font-size: 35px;
  line-height: 45px;
  margin-left: 5%;
}

.Site .Container {
  width: 90%;
  margin-left: 5%;
  margin-top: 8%;
  float: left;
  position: relative;
}

.Site .Container ol,
.Site .Container ol li,
.Site .Container ul,
.Site .Container ul li {
  list-style: none;
  margin-left: 0;
}
.Site .Container .depth1 div {
  position: relative;
  width: 100%;
  border-top: 1px solid #e5857c;
  border-bottom: 1px solid #e5857c;
  background-color: #f8f8f8;
}

.Site .Container .depth1 div a {
  font-size: 17px;
  height: 38px;
  line-height: 38px;
  padding-left: 5%;
  color: #e5857c;
  font-family: "koPubWorld DotumB";
}

.Site .Container .depth1 .left {
  float: left;
  width: 45%;
  margin-right: 5%;
}

.Site .Container .depth1 .right {
  float: left;
  width: 45%;
}
.Site .Container .depth2 {
  margin-top: 10%;
  margin-bottom: 20%;
  margin-left: 5%;
}
.Site .Container .depth2 li {
  margin-bottom: 5%;
}

.Site .Container .depth2 li a {
  font-size: 15px;
  color: #333333;
  font-family: "koPubWorld DotumM";
}

@media (max-width: 320px) {
  .Site .Container .depth2 li a {
    font-size: 10px;
  }
  .Site .Container .depth1 div a {
    font-size: 13px;
    height: 28px;
    line-height: 25px;
  }
}

@media (min-width: 320px) {
  .Site .Container .depth2 li a {
    font-size: 12px;
  }
  .Site .Container .depth1 div a {
    font-size: 15px;
    height: 30px;
    line-height: 30px;
  }
}
@media (min-width: 375px) {
  .Site .Container .depth2 li a {
    font-size: 15px;
  }
  .Site .Container .depth1 div a {
    font-size: 17px;
    height: 38px;
    line-height: 38px;
  }
}

@media (min-width: 425px) {
}

@media (min-width: 768px) {
  .Site .Container .depth2 li a {
    font-size: 22px;
  }
  .Site .Container .depth1 div a {
    font-size: 30px;
    height: 60px;
    line-height: 60px;
  }
  .Site .subtitle {
    font-size: 55px;
  }
}
