.Notice {
  border-top: solid;
  border-color: #f4f4f4;
  border-width: 52px;
  min-width: 1300px;
}

.left {
  position: relative;
  height: 750px;
  float: left;
  width: 230px;
  margin: 20px 0 0 50px;
}

/* hidden */
caption,
legend,
.hidden,
#contents .hidden {
  position: absolute;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.left .depth2 {
  position: absolute;
  margin-top: 40px;
  font-size: 30px;
  width: 230px;
  margin-left: 0;
}
.left .depth2 li {
  width: 230px;
  margin-left: 0;
}

.left .depth2 li a {
  font-size: 17px;
  font-family: "Nanum Square Bold";
  display: block;

  color: #666666;

  border-bottom: 1px solid #cecece;
  border-top: 1px solid #cecece;

  width: 100%;
  height: 55px;
  line-height: 55px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
}

.left #menu {
  background-color: #ffffff;
  padding-left: 20px;
  color: #e5857c;
  background-color: #fbf1f0;
}
#right {
  position: relative;
  float: left;

  overflow: visible;
  padding-top: 5px;
  padding-bottom: 154px;
  margin-left: 20px;
  min-width: 850px;
  width: 63%;
  max-width: 1155px;
  height: auto;
}
#right h2 {
  margin-top: 32px;
  font-family: "Nanum Square Bold";
  color: #555555;
  font-size: 40px;
  line-height: 1em;
  padding-left: 31px;
}

#right .Container {
  position: relative;
  min-width: 850px;
  width: 100%;
  max-width: 1155px;
  margin: 100px 0;
}
