.Footer {
  float: left;
  width: 100%;
  height: 300px;
  background: #fcfcfc url(../../assets/logo_footer.png) 10% 10% no-repeat;
  background-size: 180px;
}

.Footer p {
  margin-left: 5%;
  margin-bottom: 2%;
  font-family: "koPubWorld DotumM";
  font-size: 13px;
  color: #333333;
}

.Footer p:nth-child(1) {
  margin-top: 22%;
}

@media (max-width: 320px) {
  .Footer {
    margin-top: 50px;
  }
  .Footer p:nth-child(1) {
    margin-top: 30%;
  }
}
@media (min-width: 320px) {
  .Footer {
    margin-top: 0;
  }
  .Footer p:nth-child(1) {
    margin-top: 22%;
  }
}
@media (min-width: 768px) {
  .Footer {
    background: #fcfcfc url(../../assets/logo_footer.png) 9% 10% no-repeat;
    background-size: 300px;
    height: 400px;
  }
  .Footer p {
    font-size: 15px;
  }

  .Footer p:nth-child(1) {
    margin-top: 15%;
  }
}
