#SubTel {
  position: relative;
  width: 230px;
  height: 195px;
  top: 40px;

  border: 1px solid #e3e4e4;
}
#SubTel .left_ph {
  position: relative;
  width: 230px;
  height: 110px;
  background: transparent url(../assets/icon_tel.png) 12px 27px no-repeat;
  background-size: 40px 40px;
}
.ph_title {
  font-size: 16px;
  width: 200px;
  height: 20px;
  padding: 15px;
  background: #f4f4f4;

  letter-spacing: -1px;
  border-bottom: 1px solid #e3e4e4;
  font-family: "Nanum Gothic Bold";
  color: #61617a;
}
.left_ph .ph_txt1 {
  padding-left: 57px;
  position: absolute;
  top: 25px;
  font-family: "koPubWorld DotumB";
  font-size: 11px;
  letter-spacing: -0.5px;
  color: #656565;
}
.left_ph .ph_txt2 {
  padding-left: 57px;
  position: absolute;
  top: 45px;
  font-size: 24px;
  line-height: 1em;
  letter-spacing: -1px;
  font-family: "Nanum Gothic Bold";
  color: #ea4336;
}
.left_ph .ph_txt3 {
  padding-left: 12px;
  position: absolute;
  top: 85px;
  font-size: 12px;
  font-family: "koPubWorld DotumB";
  color: #333333;
}
.left_ph .ph_txt4 {
  padding-left: 12px;
  position: absolute;
  top: 105px;
  font-size: 12px;
  font-family: "koPubWorld DotumB";
  color: #333333;
}
