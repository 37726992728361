.Map {
  margin: auto;
  width: 90%;
  margin-top: 50px;
  height: 250px;
}

.MapContainer {
  width: 100%;
  height: 250px;
}

@media (min-width: 768px) {
  .Map {
    height: 400px;
  }

  .MapContainer {
    height: 400px;
  }
}
