.History {
  height: 991px;
  border-top: 3px solid #e5857c;
  border-bottom: 3px solid #e5857c;
}

.History .table {
  margin-top: 30px;
  width: 100%;
  float: left;
  border-bottom: 1px dashed #e5857c;
  padding-bottom: 30px;
}

.History .table .year {
  color: #e5857c;
  font-size: 28px;
  float: left;
  margin-left: 15px;
  margin-right: 40px;
  font-family: "koPubWorld DotumB";
}

.History .table h4 {
  width: 90px;
  text-align: right;
  color: #e5857c;
  font-size: 20px;
  float: left;
  line-height: 40px;
  margin-right: 50px;
  font-family: "koPubWorld DotumB";
}

.History .table p {
  font-weight: bold;
  font-size: 15px;
  line-height: 40px;
  font-family: "koPubWorld DotumB";
}

.History .table span {
  font-size: 15px;
  margin-left: 140px;
  font-family: "koPubWorld DotumM";
}
