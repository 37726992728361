.NavHeader {
  position: relative;
  width: 100%;
  height: 110px;
  margin: 0 auto;
  background: url(../assets/logo.png) 100% no-repeat;
  background-size: 300px 50px;
  background-position: center;
}

/* hidden */
caption,
legend,
.hidden,
#contents .hidden {
  position: absolute;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.top {
  position: absolute;
  top: 37px;
  left: 40%;
  z-index: 1;
  width: 350px;
  height: 55px;
}

/* globalmenu (ìƒë‹¨ ë©”ë‰´)*/
#globalmenu {
  position: absolute;
  right: 0;
  top: 12px;
  z-index: 1;
  text-align: left;
  margin: 0;
  padding: 0;
}
#globalmenu dt {
  visibility: hidden;
  position: absolute;
  font-size: 0;
  width: 0;
  height: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  background: none;
}
#globalmenu dd {
  float: left;
  display: block;
  padding-left: 12px;
  padding-right: 12px;
  height: 16px;
  background: url(../assets/bg_line.png) right 50% no-repeat;
}
#globalmenu dd a {
  display: block;
  font-family: "koPubWorld DotumM";
  font-size: 12px;
}
#globalmenu dd a:link,
#globalmenu dd a:visited,
#globalmenu dd a:active {
  color: #323232;
}
#globalmenu dd a:hover,
#globalmenu dd a:focus {
  color: #000;
}
