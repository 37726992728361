.SiteMap {
  border-top: solid;
  border-color: #f4f4f4;
  border-width: 52px;
  min-width: 1300px;
}

.left {
  position: relative;
  height: 500px;
  float: left;
  width: 230px;
  margin: 20px 0 0 50px;
}

/* hidden */
caption,
legend,
.hidden,
#contents .hidden {
  position: absolute;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

#right {
  position: relative;
  float: left;

  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 154px;
  margin-left: 20px;
  min-width: 850px;
  width: 63%;
  max-width: 1155px;
  height: 400px;
}
#right:after {
  display: block;
  clear: both;
  content: "";
}

#right h2 {
  margin-top: 32px;
  font-family: "Nanum Square Bold";
  color: #555555;
  font-size: 45px;
  line-height: 1em;
  padding-left: 31px;
}

#right .Container {
  position: relative;
  min-width: 850px;
  width: 63%;
  max-width: 1155px;
  margin: 100px 0;
  padding-left: 31px;
}

#right .Container ol,
#right .Container ol li,
#right .Container ul,
#right .Container ul li {
  list-style: none;
  margin-left: 0;
}

#right .Container a {
  color: #e5857c;
}

#right #navigation {
  position: relative;
  min-width: 850px;

  max-width: 1155px;
  margin: 0 auto;
  text-align: left;
}
#right .Container.active #navigation .depth2 {
  display: block;
}
#right #navigation .depth1 {
  position: absolute;

  font-size: 30px;
  width: 1020px;
}
#right #navigation .depth1 li {
  float: left;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;

  margin-right: 20px;

  width: 200px;
}

#right #navigation .depth1 li a {
  font-size: 17px;

  font-family: "koPubWorld DotumB";
  display: block;

  border-bottom: 1px solid #e5857c;
  border-top: 1px solid #e5857c;

  width: 100%;
  height: 40px;
  line-height: 38px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
}

#right #menu {
  margin-bottom: 10px;
  background-color: #f4f4f4;
  padding-left: 20px;
}

#right #navigation .depth2 {
  font-size: 18px;
  display: block;
  height: 320px;
  background-color: #ffffff;

  color: #666666;
}
#right #navigation .depth2 li {
  float: none;

  border: 0;
  background-color: #ffffff;
}
#right #navigation .depth2 li a {
  font-size: 15px;
  font-family: "koPubWorld DotumM";
  text-decoration: none;
  font-weight: 0;
  text-align: left;
  color: #666666;
  border-bottom: none;
  border-top: none;
  height: 32px;
  line-height: 30px;
  padding-left: 20px;
}
#right #navigation .depth2 li a:hover {
  color: #e5857c;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.5);
}
