.Item {
  position: relative;
  float: left;
  border-top: 3px solid #e5857c;
  min-width: 850px;
  width: 100%;
}

.Item .title {
  float: left;
  min-width: 850px;
  width: 100%;
}

.Item .title h1 {
  font-size: 20px;
  color: #333333;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-bottom: 1px solid #c8c8c8;
}

.Item .info {
  min-width: 850px;
  width: 100%;
  float: left;

  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c8c8c8;
}

.Item .info p {
  float: left;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Item .info p:nth-child(1) {
  width: 9%;
  padding-left: 20px;
  min-width: 80px;
  font-weight: bold;
  border-right: 2px solid #c8c8c8;
}
.Item .info p:nth-child(2) {
  width: 38%;
  min-width: 300px;
  padding-left: 10px;
}
.Item .info p:nth-child(3) {
  width: 9%;
  min-width: 80px;
  font-weight: bold;
  border-right: 2px solid #c8c8c8;
}
.Item .info p:nth-child(4) {
  width: 38%;
  min-width: 300px;
  padding-left: 10px;
}

.Item .file {
  min-width: 850px;
  width: 100%;
  float: left;

  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c8c8c8;
}
.Item .file p {
  float: left;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Item .file p:nth-child(1) {
  width: 9%;
  padding-left: 20px;
  min-width: 80px;
  font-weight: bold;
  border-right: 2px solid #c8c8c8;
}
.Item .file p:nth-child(2) {
  width: 85%;
  min-width: 300px;
  padding-left: 10px;
}

.Item .content {
  min-width: 850px;
  min-height: 250px;

  max-height: 1000px;
  width: 100%;
  float: left;

  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid #e5857c;
  overflow: auto;
}

.Item .content div {
  margin-top: 50px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.Item a {
  float: right;
  margin-top: 10px;
  font-weight: bold;
  font-size: 15px;
  background: url("../assets/notice.png") center center no-repeat;
  background-size: 150px 40px;
  color: #fff;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
}

.Item button {
  margin-left: 10px;
  float: left;
  font-size: 13px;
  height: 30px;
  padding-left: 25px;
  background: url(../assets/attach.png) left center no-repeat;
}
