.Preview .more {
  list-style: none;
  position: absolute;
  top: -60px;
  right: 10px;
  border: 0 !important;
  background-color: #f4f4f4;
  background-image: none;
  width: 10px;
  height: 10px;
  margin: 0;
  padding: 0;
}
.Preview .more a {
  line-height: 1em;
  display: block;
}

.Preview li {
  float: left;
  margin-bottom: -1px;
  color: #1976d2;
  font-family: "koPubWorld DotumB";
  font-size: 5px;
}

.Preview span {
  float: right;
  color: #999999;
  font-family: "koPubWorld DotumM";
  font-size: 14px;
}

.Preview a.subtitle {
  font-size: 14px;
  display: block;
  width: 300px;
  float: left;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.Preview .preImage {
  margin-top: 20px;
  float: left;
  width: 40%;
  height: 167px;
  margin-left: 20px;
  margin-right: 20px;
}

.Preview .preImage a:hover,
.Preview .preImage a:active,
.Preview .preImage a:focus {
  color: #323232;
  text-decoration: underline;
}

.Preview .preImage .photo {
  width: 221px;
  height: 133px;
  border: 1px solid #b2b2b2;
  background: #f4f4f4 url(../assets/logo_preview.png) 50% 50% no-repeat;
}
.Preview .preImage .p_title {
  float: left;
  color: #333333;
  width: 221px;
  margin-top: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "koPubWorld DotumM";
  white-space: nowrap;
  word-break: break-all;
  font-size: 14px;
}
