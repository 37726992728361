.depth1 {
  position: absolute;
  margin-top: 55px;
  font-size: 30px;
  width: 100%;
  margin-left: 0;
}
.depth1 li {
  width: 100%;
  margin-left: 0;
}
.depth1 li a {
  font-size: 20px;
  font-family: "Nanum Gothic Bold";
  display: block;

  background: url("../assets/more.png") 90% center no-repeat;
  background-size: 20px;
  color: #ffffff;

  border-top: 1px solid #cecece;

  width: 100%;
  height: 55px;
  line-height: 55px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  padding-left: 20px;
}

.depth1 button {
  font-size: 20px;
  font-family: "Nanum Gothic Bold";
  display: block;

  color: #ffffff;
  border-top: 1px solid #cecece;

  width: 100%;
  height: 55px;
  line-height: 55px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  padding-left: 20px;

  background: url("../assets/more.png") 90% center no-repeat;
  background-size: 20px;
  text-align: left;
}

.depth1 {
  position: absolute;
  margin-top: 55px;
  font-size: 30px;
  width: 100%;
  margin-left: 0;
}

.depth2 {
  font-size: 17px;
  display: block;

  background-color: #f5d2ce;
  color: #666666;
  font-family: "Nanum Square Bold";
  margin-left: 0px;
}
.depth2 li {
  float: none;
  background-color: none;
  border: 0;
}
.depth2 li a {
  font-size: 18px;
  padding-left: 0px;
  height: 52px;
  line-height: 52px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  color: #666666;
  background: none;
}

.depth2 li a:hover {
  color: #e5857c;
  text-decoration: none;
  background-color: rgba(229, 133, 124, 0.2);
}

.depth1 .last {
  border-bottom: 1px solid #cecece;
}
.depth1 .banner {
  margin-top: 100px;
  text-align: right;
}

.depth1 .banner a {
  border: 0;
  background: none;
  font-family: "koPubWorld DotumM";
  font-size: 12px;
  height: 40px;
  padding-left: 0px;
  padding-right: 20px;

  margin-left: 0px;
  margin-right: 20px;
  line-height: 40px;
}

@media (max-width: 320px) {
}

@media (min-width: 768px) {
  .depth1 {
    margin-top: 70px;
  }
  .depth1 button {
    font-size: 25px;
    height: 70px;
    line-height: 70px;
  }
  .depth2 li a {
    font-size: 20px;

    height: 55px;
    line-height: 55px;
  }
  .depth1 .banner a {
    font-size: 15px;

    height: 55px;
    line-height: 55px;
  }
}
