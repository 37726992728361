.quick {
  width: 87px;
  position: fixed;
  z-index: 1;
  top: 680px;
  left: 50%;
  margin-left: 535px;
}

.q_q_menu ul {
  width: 240px;
  margin: 0 auto;
}
.q_q_menu ul li {
  text-align: center;
}

.q_q_menu ul li img {
  width: 200px;
  height: 187px;
}
