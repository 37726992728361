.Nav {
  position: relative;
  min-width: 1300px;
  width: 100%;
  height: 162px;
}

.Nav:after {
  display: block;
  clear: both;
  content: "";
}
