.History {
  margin-top: 10%;
  width: 92%;
  height: 933px;
  border-top: 3px solid #e5857c;
  border-bottom: 3px solid #e5857c;
  margin: auto;
  margin-top: 10%;
}

.History .table {
  margin-top: 5%;
  width: 100%;
  float: left;
  border-bottom: 1px dashed #e5857c;
  padding-bottom: 5%;
}

.History .table .year {
  color: #e5857c;
  font-size: 22px;
  margin-left: 8%;
  font-family: "koPubWorld DotumB";
}

.History .table h4 {
  width: 25%;
  float: left;
  color: #e5857c;
  font-size: 18px;
  text-align: right;
  line-height: 26px;
  margin-left: -7%;
  margin-right: 5%;
  font-family: "koPubWorld DotumB";
}

.History .table p {
  width: 100%;
  font-size: 16px;

  font-family: "koPubWorld DotumB";
}

.History .table span {
  font-size: 14px;
  margin-left: 23%;
  font-family: "koPubWorld DotumM";
}
.History .twoh {
  margin-top: 1%;
}

@media (max-width: 320px) {
  .History {
    height: 807px;
  }
  .History .table .year {
    font-size: 18px;
    margin-left: 5%;
  }

  .History .table h4 {
    font-size: 15px;
    line-height: 24px;
    margin-left: -8%;
    margin-right: 5%;
  }
  .History .table p {
    font-size: 13px;
    line-height: 23px;
  }
  .History .table span {
    font-size: 10px;
    margin-left: 22%;
  }
}

@media (min-width: 320px) {
  .History {
    height: 890px;
  }
  .History .table .year {
    font-size: 21px;
    margin-left: 5%;
  }

  .History .table h4 {
    font-size: 17px;
    line-height: 24px;
    margin-left: -8%;
    margin-right: 5%;
  }
  .History .table p {
    font-size: 15px;
    line-height: 23px;
  }
  .History .table span {
    font-size: 12px;
    margin-left: 22%;
  }
}
@media (min-width: 375px) {
  .History {
    height: 926px;
  }

  .History .table .year {
    font-size: 22px;
    margin-left: 8%;
  }

  .History .table h4 {
    font-size: 18px;
    line-height: 26px;
    margin-left: -7%;
    margin-right: 5%;
  }
  .History .table p {
    font-size: 16px;
  }
  .History .table span {
    font-size: 14px;
    margin-left: 23%;
  }
}

@media (min-width: 425px) {
  .History {
    height: 968px;
  }
}

@media (min-width: 768px) {
  .History {
    height: 1328px;
  }

  .History .table .year {
    font-size: 27px;
    margin-left: 8%;
  }

  .History .table h4 {
    font-size: 21px;
    line-height: 26px;
    margin-left: -10%;
    margin-right: 5%;
  }
  .History .table p {
    font-size: 19px;
    line-height: 25px;
  }
  .History .table span {
    font-size: 16px;
    margin-left: 20%;
  }
}
