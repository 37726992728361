.Navm {
  position: relative;
  float: left;
  width: 100%;
  height: 8%;
  background: url("../../assets/logo.png") center center no-repeat;
  background-size: 50% 50%;
}

/* hidden */
caption,
legend,
.hidden,
#contents .hidden {
  position: absolute;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.Nav {
  width: 10%;
  height: 50px;
  float: right;
}
.Nav img {
  height: 25px;
}

.sidebar {
  position: absolute;
  width: 65%;
  height: 1050px;
  background-color: #e5857c;
  z-index: 1000;
}

@media (max-width: 320px) {
  .Navm {
    height: 7%;
  }
}

@media (min-width: 768px) {
  .Navm {
    height: 85px;
  }

  .Nav {
    width: 10%;
    height: 100%;
    float: right;
  }
  .Nav img {
    height: 35px;
  }
}
