.PageNav {
  margin-top: 20px;
  float: left;
  min-width: 850px;
  width: 98%;
  margin-top: 50px;
  text-align: center;
}

.PageNav button {
  margin-left: 30px;
  color: #666666;
}

.PageNav .page {
  font-size: 20px;

  font-family: "Nanum Square Bold";
}
