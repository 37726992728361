.Intro {
  border-top: solid;
  border-color: #f4f4f4;
  border-width: 52px;
  min-width: 1400px;
}

.Intro2 {
  width: 80%;         /* 너비를 80%로 설정 */
  margin: 0 auto;     /* 상하 여백은 0, 좌우 여백은 자동으로 계산 */
  min-width: 1300px;  /* 최소 너비 설정. 이 값을 조절하여 원하는 최소 크기를 지정하세요. */
}

.left {
  position: relative;
  height: 750px;
  float: left;
  width: 230px;
  margin: 20px 0 0 210px;
}

/* hidden */
caption,
legend,
.hidden,
#contents .hidden {
  position: absolute;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.left .depth2 {
  position: absolute;
  margin-top: 40px;
  font-size: 30px;
  width: 230px;
  margin-left: 0;
}
.left .depth2 li {
  width: 230px;
  margin-left: 0;
}

.left .depth2 li a {
  font-size: 17px;
  font-family: "Nanum Square Bold";
  display: block;
  color: #666666;

  border-bottom: 1px solid #cecece;
  border-top: 1px solid #cecece;

  width: 100%;
  height: 55px;
  line-height: 55px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
}

.left #menu {
  background-color: #ffffff;
  padding-left: 20px;
}

#right {
  position: relative;
  float: left;

  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 154px;
  margin-left: 20px;
  min-width: 850px;
  width: 52%;
  max-width: 1155px;
  height: 400px;
}
#right:after {
  display: block;
  clear: both;
  content: "";
}

#right h2 {
  margin-top: 32px;
  font-family: "Nanum Square Bold";
  color: #555555;
  font-size: 40px;
  line-height: 1em;
  padding-left: 31px;
}

#right .Container {
  position: relative;
  min-width: 850px;
  width: 100%;
  max-width: 1155px;
  margin: 40px 0;
  padding-left: 31px;
}
#right .Container img {
  width: 96%;
  height: 100%;
}

#right .one {
  position: relative;
  min-width: 820px;
  width: 63%;
  max-width: 1155px;
  height: 400px;
  background: right top no-repeat;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(../assets/main_logo.png);
  background-size: 280px 280px;
}

#right .one h1 {
  font-size: 30px;
  font-family: "koPubWorld DotumB";
  color: #333333;
  margin-bottom: 20px;
}

#right .two {
  width: 61%;
}

#right .two .p2 {
  margin-top: 30px;
}
#right .two p {
  font-size: 17px;
  font-family: "koPubWorld DotumM";
  color: #333333;
}

#right .three {
  width: 95%;
  margin-top: 30px;
}
#right .three p {
  font-family: "koPubWorld DotumB";
  color: #2679b2;
  font-size: 19px;
}
#right .list li {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

#right .list2 h1 {
  background: url("../assets/list.png") left no-repeat;
  background-size: 20px 20px;
  color: #e5857c;
  font-size: 20px;
  padding-left: 30px;
  margin-bottom: 18px;
  font-family: "Nanum Gothic Bold";
}
#right .list2 li {
  margin-top: 10px;

  color: #1976d2;
  font-family: "koPubWorld DotumB";
}

#right .list2 .addr {
  color: #333333;
}

#right .list3 {
  list-style: disc;

  margin-left: 30px;
  font-family: "koPubWorld DotumB";
}

#right .list3 li {
  padding-left: 10px;
  color: #333333;
}

#right .list3 p {
  font-size: 16px;
  padding-left: 10px;
  color: #333333;
}
