/* hidden */
caption,
legend,
.hidden,
#contents .hidden {
  position: absolute;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

#location {
  position: relative;
  height: 36px;
}
#location p {
  font-size: 8pt;
  height: 37px;
  line-height: 37px;
  border-bottom: 2px solid #666666;

  padding-left: 20px;
  font-family: "nbg";
  font-size: 13px;
}
#location p a {
  font-size: 9pt;
  color: #8c8c8c;
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: "koPubWorld DotumM";
}
#location p strong {
  font-size: 9pt;
  color: #646464;
  font-weight: bold;
  font-size: 13px;
  font-family: "koPubWorld DotumB";
}
#location p a.path_home {
  width: 14px;
  height: 14px;
  background: url(../assets/icon_home.png) left 50% no-repeat;
  background-size: 14px 14px;
  display: inline-block;
  position: relative;
  top: 2px;
}
#location p a:link,
#loction p a:visited {
  margin: 0 2px;
  color: #8c8c8c;
}
#location p a:hover,
#location p a:focus,
#loaction p a:active {
  text-decoration: none;
  color: #252525;
}
