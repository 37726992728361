/* hidden */
caption,
legend,
.hidden,
#contents .hidden {
  position: absolute;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

/*Main Image*/
#m_key_box {
  position: relative;
  min-width: 1020px;
  max-width: 100%;
  height: 503px;
  background: #fcf5f4;
  display: flex;
  align-items: center;
}
#m_key_box:after {
  display: block;
  clear: both;
}
.m_key {
  position: relative;
  width: 1020px;
  height: 362px;

  background: #fcf5f4 url("../assets/Main.png") no-repeat;
  background-size: 100% 100%;
  margin: auto auto;
}
.m_key .m_key_txt {
  position: relative;
  width: 1020px;
  height: 503px;
}
.m_key:after {
  display: block;
  clear: both;
}

#m_container {
  position: relative;
  min-width: 1020px;
  width: 100%;
  height: 570px;
  background: #fff;
}
#m_container:after {
  display: block;
  clear: both;
}

#m_contents2_box {
  position: relative;
  width: 1020px;
  height: 230px;
  margin: 0 auto;
  margin-top: 25px;
}
#m_contents2:after {
  display: block;
  clear: both;
  content: "";
}

/*바로가기 메뉴*/
#ctn2_quick {
  position: relative;
  float: left;
  width: 600px;
  height: 230px;
  border: 1px solid #d7d7d7;
  font-family: "Nanum Gothic Bold";
}
.ctn_title {
  float: left;
  width: 107px;
  font-size: 20px;
  margin-top: 21px;
  padding-left: 22px;
  font-weight: bold;
  margin-right: 30px;
  color: #e5857c;
  font-family: "Nanum Gothic Bold";
}
.ctn2_txt {
  font-size: 16px;
  color: #333333;
  margin-top: 21px;
  line-height: 24px;

  font-family: "koPubWorld DotumB";
}
.care_list {
  position: relative;
  top: 16px;
  width: 549px;
  height: 152px;
  margin: 0 auto;
}
.care_list li {
  float: left;
  width: 121px;
  height: 152px;
  margin-right: 13px;

  padding: 0;
}
.care_list li.sv1 {
  margin-left: 13px;
  background: url(../assets/sv1.png) no-repeat left top;
  background-size: 100% 100%;
}
.care_list li.sv2 {
  background: url(../assets/sv2.png) no-repeat left top;
  background-size: 100% 100%;
}
.care_list li.sv3 {
  background: url(../assets/sv3.png) no-repeat left top;
  background-size: 100% 100%;
}
.care_list li.sv4 {
  background: url(../assets/sv4.png) no-repeat left top;
  background-size: 100% 100%;
}

.care_list li a {
  display: block;
  width: 121px;
  height: 152px;
  color: #323232;
  font-size: 14px;
  font-family: "nbg", "êµ´ë¦¼", "Gulim", "sans-serif", "Tahoma";
}
.care_list li a:hover,
a:focus,
a:active {
  text-decoration: none;
}
.care_list li a span {
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
  top: 124px;
}

/* 서비스 안내 */
#ctn2_tel {
  position: relative;
  float: left;
  width: 415px;
  height: 230px;
  border: 1px solid #d7d7d7;
  font-family: "Nanum Gothic Bold";
}
.ctn_title2 {
  width: 107px;
  font-size: 20px;
  margin-top: 21px;
  padding-left: 30px;
  color: #e5857c;
  font-family: "Nanum Gothic Bold";
}
.ctn3_txt {
  font-size: 16px;
  color: #212121;
  padding-left: 30px;
  margin-top: 12px;
  font-family: "koPubWorld DotumB";
}
.ctn3_tel1 {
  font-size: 37px;
  font-family: "Nanum Gothic Bold";
  font-weight: bold;
  color: #ea4336;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 80px;
  line-height: 1.5em;
  background: url(../assets/icon_tel.png) no-repeat 30px 50%;
}
.ctn3_tel2 {
  padding-left: 30px;
  font-size: 16px;
  line-height: 1.5em;
  font-family: "koPubWorld DotumB";
}
.ctn3_tel2 dt {
  float: left;
  margin-right: 5px;
}

/* 게시판 */
#m_contents3_box {
  width: 1020px;
  height: 228px;
  margin: 0 auto;
  margin-top: 25px;
  font-family: "Nanum Gothic Bold";
}
#m_contents3:after {
  display: block;
  clear: both;
  content: "";
}

#Tabbox2 {
  position: relative;
  float: left;
  width: 507px;
  height: 252px;
  border: 1px solid #d0d0d0;
  border-right: 0;
}

.tab_list {
  height: 303px;
}
.tab_list ul {
  height: 48px;
  background-color: #f4f4f4;
  position: relative;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d7d7d7;
  list-style: none;
  *zoom: 1;
  overflow: visible;
}
.tab_list ul:after {
  display: block;
  clear: both;
  content: "";
}
.tab_list li {
  float: left;
  margin-bottom: -1px;
}
.tab_list li a {
  float: left;
  position: relative;
  border-right: 1px solid #d7d7d7;
  background: #f4f4f4;
  color: #666;
  text-decoration: none;
}
.tab_list li a span {
  display: inline-block;
  padding: 15px 30px 15px 30px;
  cursor: pointer;
  font-weight: 600;
  color: #616161;
  font-family: "Nanum Gothic Bold";
  font-size: 17px;
}
.tab_list li ul {
  display: none;
  position: absolute;
  top: 70px;
  left: 23px;
  width: 460px;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  *zoom: 1;
  background-color: #fff;
}
.tab_list li ul:after {
  display: block;
  clear: both;
  content: "";
}
.tab_list li li {
  float: none;
  position: relative;
  margin: 0 0 15px 15px;
  list-style: disc;
  padding-left: 15px;
}
.tab_list li li.first {
  padding-left: 0;
  background: none;
}
.tab_list li li.first .f_title {
  font-size: 14px;
  color: #5a5a5a;
}
.tab_list li li a {
  float: none;
  margin: 0;
  padding: 0;
  border: 0 !important;
  background: transparent;
  font-weight: normal;
  color: #474747 !important;
  letter-spacing: normal;

  font-size: 14px;
}
.tab_list li li a:hover,
.tab_list li li a:active,
.tab_list li li a:focus {
  color: #000 !important;
  text-decoration: underline;
}
.tab_list li li p.board_ctn {
  padding-top: 15px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
  line-height: 1.6em;
}
.tab_list li li p.board_ctn a {
  line-height: 1.6em;
}
.tab_list li li span.date {
  float: right;
  color: #7e7e7e;

  font-size: 14px;
}
.tab_list li li span.new {
  width: 11px;
  height: 11px;
}
.tab_list li li span.new img {
  margin-left: 5px;
  margin-top: 2px;
}

.tab_list li li.more {
  list-style: none;
  position: absolute;
  top: -60px;
  right: 10px;
  border: 0 !important;
  background-color: #f4f4f4;
  background-image: none;
  width: 10px;
  height: 10px;
  margin: 0;
  padding: 0;
}
.tab_list li li.more a {
  line-height: 1em;
  display: block;
}

.tab_list.m1 .m1 a,
.tab_list.m2 .m2 a,
.tab_list.m3 .m3 a,
.tab_list.m4 .m4 a,
.tab_list.m5 .m5 a,
.tab_list.m6 .m6 a,
.tab_list.m7 .m7 a,
.tab_list.m8 .m8 a,
.tab_list.m9 .m9 a,
.tab_list.m10 .m10 a,
.tab_list.m11 .m11 a,
.tab_list.m12 .m12 a {
  margin-top: -1px;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #ccc;
}
.tab_list.m1 .m1 a span,
.tab_list.m2 .m2 a span,
.tab_list.m3 .m3 a span,
.tab_list.m4 .m4 a span,
.tab_list.m5 .m5 a span,
.tab_list.m6 .m6 a span,
.tab_list.m7 .m7 a span,
.tab_list.m8 .m8 a span,
.tab_list.m9 .m9 a span,
.tab_list.m10 .m10 a span,
.tab_list.m11 .m11 a span,
.tab_list.m12 .m12 a span {
  padding-top: 15px;
  color: #1b1b1b;
  background-color: #fff;
}

.tab_list.m1 .m1 ul,
.tab_list.m2 .m2 ul,
.tab_list.m3 .m3 ul,
.tab_list.m4 .m4 ul,
.tab_list.m5 .m5 ul,
.tab_list.m6 .m6 ul,
.tab_list.m7 .m7 ul,
.tab_list.m8 .m8 ul,
.tab_list.m9 .m9 ul,
.tab_list.m10 .m10 ul,
.tab_list.m11 .m11 ul,
.tab_list.m12 .m12 ul {
  display: block;
}
.tab_list.js_off {
  height: auto;
}
.tab_list.js_off ul {
  border: 0;
}
.tab_list.js_off li {
  float: none;
  position: relative;
}
.tab_list.js_off li a {
  display: block;
  float: none;
  border: 1px solid #eee !important;
  border-bottom-color: #ccc !important;
  background: #fafafa !important;
  font-weight: bold;
  color: #333;
}
.tab_list.js_off li ul {
  position: static;
  display: block;
  left: 0;
  top: 0;
  padding: 5px 0;
}
.tab_list.js_off li li a {
  display: inline;
  border: 0 !important;
  background: none !important;
  font-weight: normal;
}
.tab_list.js_off li li.more {
  top: 7px;
  right: 15px;
  background: transparent;
}
.tab_list li li a:hover,
.tab_list li li a.subtitle {
  display: block;
  width: 300px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

/*포토갤러리*/
/* í¬í† ê°¤ëŸ¬ë¦¬ */
#ctn3_photo {
  position: relative;
  float: left;
  width: 510px;
  height: 252px;
  border: 1px solid #d7d7d7;
  background: #fff;
}
.ctn_title4 {
  width: 488px;
  height: 48px;
  line-height: 48px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #cdcdcd;
  font-family: "Nanum Gothic Bold";
  font-size: 17px;
  color: #323232;
  padding-left: 22px;
}
.ctn_photo {
  width: 466px;
  margin: 0 auto;
  margin-top: 20px;
}
.ctn_photo li {
  float: left;
  width: 223px;
  height: 167px;
  margin-right: 20px;
}
.ctn_photo li.mg_right0 {
  margin-right: 0;
}
/* .ctn_photo li {
  font-family: "nbg", "êµ´ë¦¼", "Gulim", "sans-serif", "Tahoma";
} */
.ctn_photo li div {
  width: 221px;
  height: 133px;
  border: 1px solid #b2b2b2;
  background: #f4f4f4 url(../assets/logo_preview.png) 50% 50% no-repeat;
}
.ctn_photo li p.p_title {
  padding-top: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.ctn_photo li a {
  color: #5f5f5f;
  font-size: 14px;
}
.ctn_photo li a:hover,
.ctn_photo li a:active,
.ctn_photo li a:focus {
  color: #323232;
  text-decoration: underline;
}
#ctn3_photo a.more {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 30px;
  height: 10px;
  display: block;
}
