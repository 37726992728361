.Pages {
  width: 100%;
  float: left;
}
.Pages .depth2 {
  width: 100%;
  margin: 0;
}
.Pages .depth2 li {
  text-align: center;
  float: left;
  width: 50%;
  height: 35px;
  margin-left: 0;

  line-height: 32px;
  background-color: #f8f8f8;
  border-bottom: 2px solid #cecece;
}
.Pages .depth2 .noti {
  width: 100%;
}

.Pages .depth2 li a {
  text-decoration: none;
  font-size: 14px;

  color: #666666;
  font-family: "Nanum Square Bold";
}
.Pages .subtitle {
  float: left;
  width: 60%;
  white-space: pre-line;
  margin-top: 5%;
  font-family: "Nanum Square Bold";
  color: #555555;
  font-size: 35px;
  line-height: 45px;
  margin-left: 5%;
}
