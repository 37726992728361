.Care .depth2 {
  width: 100%;
  margin: 0;
}

.Care .depth2 li {
  text-align: center;
  float: left;
  width: 25%;
  height: 40px;

  margin-left: 0;

  line-height: 40px;
  background-color: #f8f8f8;
  margin-right: 1%;
  border-bottom: 2px solid #cecece;
}

.Care .depth2 li a {
  text-decoration: none;
  font-size: 11px;

  color: #666666;
  font-family: "Nanum Square Bold";
}

.Care .depth2 li:nth-child(3) a {
  font-size: 10px;
}

.Care .depth2 li:last-child {
  margin-right: 0;
  width: 22%;
}

.Care .subtitle {
  float: left;
  width: 60%;
  white-space: pre-line;
  margin-top: 5%;
  font-family: "Nanum Square Bold";
  color: #555555;
  font-size: 35px;
  line-height: 45px;
  margin-left: 5%;
}

.Care .image {
  width: 20%;
  height: 10%;
  float: left;
  z-index: 900;
  margin-top: 8%;
}

.service {
  margin-top: 60%;
  width: 100%;
  background: #fdfaf9 url(../assets/service.png) no-repeat center 40%;
  background-size: 50% 50%;
}

.service p {
  font-family: "koPubWorld DotumB";
  font-size: 17px;
  text-align: center;
}

.service p:nth-child(1) {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  width: 90%;
  margin-bottom: 50%;
}
.service p:nth-child(2) {
  font-family: "Nanum Gothic Bold";
  font-size: 40px;
  color: #d75c56;
  font-weight: bold;
  margin-bottom: 5%;
}
.service p:nth-child(3) {
  font-size: 13px;
  padding-bottom: 5%;
}
@media (max-width: 320px) {
  .Care .depth2 li a {
    font-size: 10px;
  }

  .Care .depth2 li:nth-child(3) {
    width: 30%;
  }

  .Care .depth2 li:nth-child(4) {
    width: 17%;
  }

  .Care .subtitle {
    font-size: 25px;
    line-height: 35px;
  }
}

@media (min-width: 320px) {
  .Care .depth2 li a {
    font-size: 11px;
  }

  .Care .depth2 li:nth-child(3) a {
    font-size: 10px;
  }
  .Care .subtitle {
    font-size: 28px;
    line-height: 38px;
  }

  .Care .image {
    width: 20%;
    height: 8%;
  }
  .service p {
    font-size: 15px;
  }
  .service {
    background: #fdfaf9 url(../assets/service.png) no-repeat center 35%;
    background-size: 50% 50%;
  }
  .service p:nth-child(3) {
    font-size: 11px;
  }
  .Care .depth2 li:nth-child(3) {
    width: 25%;
  }

  .Care .depth2 li:nth-child(4) {
    width: 22%;
  }
}
@media (min-width: 375px) {
  .Care .depth2 li a {
    font-size: 13px;
  }

  .Care .depth2 li:nth-child(3) a {
    font-size: 12px;
  }

  .Care .subtitle {
    font-size: 35px;
    line-height: 45px;
  }

  .Care .image {
    width: 20%;
    height: 10%;
  }

  .service p {
    font-size: 17px;
  }
  .service {
    background: #fdfaf9 url(../assets/service.png) no-repeat center 40%;
    background-size: 50% 50%;
  }
  .service p:nth-child(3) {
    font-size: 13px;
  }
}

@media (min-width: 425px) {
  .Care .depth2 li a {
    font-size: 14px;
  }

  .Care .depth2 li:nth-child(3) a {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .service {
    margin-top: 40%;
  }
  .Care {
    height: 1500px;
  }
  .Care .depth2 li a {
    font-size: 18px;
  }

  .Care .depth2 li:nth-child(3) a {
    font-size: 17px;
  }
  .Care .subtitle {
    font-size: 45px;
    line-height: 65px;
  }

  .Care .image {
    width: 20%;
    height: 15%;
  }

  .service p {
    font-size: 30px;
  }
  .service {
    background: #fdfaf9 url(../assets/service.png) no-repeat center 40%;
    background-size: 50% 50%;
  }
  .service p:nth-child(2) {
    font-size: 70px;
  }
  .service p:nth-child(3) {
    font-size: 25px;
  }
}
