.state {
  font-size: 20px;
  float: left;
  color: #333333;
  line-height: 33px;
  font-family: "koPubWorld DotumB";
}
.search {
  float: right;
  margin-right: 30px;
  color: #333333;
  font-family: "koPubWorld DotumB";
}

.search select {
  width: 80px;
  height: auto;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff url(../assets/arrow.png) no-repeat 55px 10px;

  margin-right: 10px;
}

.search input {
  width: 200px;
  height: auto;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 4px;
  padding-left: 10px;
  margin-right: 10px;
}
.search .hidden {
  display: none;
}

.search img {
  width: 30px;
}

.table {
  margin-top: 20px;
  float: left;
  min-width: 850px;
  width: 98%;
  border-top: 2px solid #c8c8c8;
  border-bottom: 2px solid #c8c8c8;
}

.table li .ItemListItem {
  width: 30%;
  text-align: center;
  float: left;
  color: #333333;

  padding-right: 3%;
}

.table li .ItemListItem a:hover {
  text-decoration: none;
}

.table li .ItemListItem .image {
  padding-top: 75%;
  margin-top: 30px;
  margin-bottom: 15px;
  float: left;
  width: 100%;

  background: #f4f4f4 url(../assets/logo_preview.png) 50% 50% no-repeat;
}

.table li .ItemListItem .image2 {
  margin-top: 30px;
  margin-bottom: 15px;
  float: left;
  width: 100%;
  padding-top: 75%;
}

.table li .ItemListItem .pre {
  width: 100%;
  height: 100%;
}

.table li .ItemListItem p {
  font-size: 20px;
  float: left;
  color: #333333;
  text-align: left;
  margin-bottom: 10px;
}

.table li .ItemListItem p:nth-child(2) {
  min-width: 50px;
  width: 100%;
  font-family: "koPubWorld DotumB";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.table li .ItemListItem p:nth-child(3) {
  min-width: 50px;
  width: 60%;
  font-size: 16px;
  font-family: "koPubWorld DotumM";
}
.table li .ItemListItem p:nth-child(4) {
  text-align: right;
  min-width: 50px;
  width: 38%;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: "koPubWorld DotumM";
}
