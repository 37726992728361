.Preview {
  margin-left: 5%;
  padding-left: 5%;
  margin-bottom: 25px;
}

.Preview li {
  list-style: disc;
}

.Preview .more {
  list-style: none;
  position: absolute;

  right: 7%;
  top: 19px;
  float: left;
  border: 0;
  background-color: #fff;
  background-image: none;
  width: 10px;
  height: 10px;
  margin: 0;
  padding: 0;
}
.Preview .more a {
  line-height: 1em;
  display: block;
}

.Preview li {
  float: left;
  margin-bottom: 10px;
  color: #1976d2;
  font-family: "koPubWorld DotumB";
  font-size: 1px;
}

.Preview span p {
  float: right;
  color: #999999;
  font-family: "koPubWorld DotumM";
  font-size: 13px;
}

.Preview a.subtitle {
  font-size: 14px;
  line-height: 19px;
  display: block;
  width: 280px;
  padding-left: 15px;
  float: left;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.Preview .preImage {
  list-style: none;
  margin-top: 20px;
  float: left;
  width: 42%;
  height: 150px;
  margin-right: 5%;
}

.Preview .preImage .photo {
  width: 100%;
  height: 60%;
  border: 1px solid #b2b2b2;
  background: #f4f4f4 url(../../assets/logo_preview.png) 50% 50% no-repeat;
}
.Preview .preImage .p_title {
  float: left;
  color: #333333;
  width: 100%;
  margin-top: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "koPubWorld DotumM";
  white-space: nowrap;
  word-break: break-all;
  font-size: 14px;
}

@media (max-width: 320px) {
  .Preview a.subtitle {
    width: 150px;
  }
  .Preview li {
    margin-bottom: 5px;
  }
  .Preview .preImage .photo {
    height: 50%;
  }
}

@media (min-width: 320px) {
  .Preview .preImage .photo {
    height: 60%;
  }
  .Preview li {
    margin-bottom: 10px;
  }

  .Preview a.subtitle {
    width: 190px;
  }
}
@media (min-width: 375px) {
  .Preview a.subtitle {
    width: 240px;
  }
}

@media (min-width: 425px) {
  .Preview a.subtitle {
    width: 280px;
  }
}

@media (min-width: 768px) {
  .Preview a.subtitle {
    width: 570px;
    font-size: 20px;
  }
  .Preview span p {
    font-size: 15px;
  }
  .Preview li {
    margin-bottom: 20px;
  }
  .Preview .more {
    right: 7%;
    top: 25px;
  }
  .Preview .more img {
    width: 30px;
  }

  .Preview .preImage {
    height: 250px;
  }

  .Preview .preImage .p_title {
    font-size: 20px;
  }
}
