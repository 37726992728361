#footer {
  position: relative;
  clear: both;
  background-color: #fcfcfc;
  min-width: 1300px;
  max-width: 100%;
  height: 160px;
  border-top: 1px solid #d5d5d5;
  font-family: "koPubWorld DotumM";
  color: #333333;
  letter-spacing: 0;
  vertical-align: middle;
}
#footer_box {
  position: relative;
  width: 65%;
  height: 109px;
  margin: 0 auto;
  top: 25.5px;
  background: url(../assets/logo_footer.png) left no-repeat;
  background-size: 290px;
}

#copy {
  position: relative;
  top: 14.5px;
  width: 100%;
  height: 80px;
}
#copy_box {
  padding-left: 350px;
}
#copy_box address {
  font-style: normal;
  white-space: nowrap;
}
#copy_box address span {
  font-family: "koPubWorld DotumM";
  font-size: 14px;
  color: #333333;
}
#copy_box address span.mg_right20 {
  margin-right: 25px;
}
#copy_box address .f_addr {
  position: absolute;
  top: 20px;
}
#copy_box address .f_tel {
  position: absolute;
  top: 40px;
}
/* #copy_box p.f_copy {
  position: absolute;
  top: 70px;
  font-size: 14px;
  color: #535252;
} */
