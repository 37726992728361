.CareList .name {
  background: url("../../assets/list.png") left no-repeat;
  background-size: 17px 17px;
  color: #e5857c;
  font-size: 19px;
  padding-left: 7%;
  margin-bottom: 4%;
  font-family: "Nanum Gothic Bold";
}

.CareList .list1 {
  background-color: #f4f4f4;
  padding: 3%;
  margin-bottom: 5%;
}

.CareList .list1 .list2 {
  width: 100%;
}
.CareList .list1 .list2 p {
  font-size: 14px;
  font-family: "koPubWorld DotumM";
  word-break: keep-all;
}

.CareList .list3 {
  margin-bottom: 5%;
  white-space: pre-wrap;
}

.CareList .list3 p {
  font-family: "koPubWorld DotumB";
  font-size: 14px;
  text-indent: -2.3em;
  margin-left: 2.3em;
  line-height: 24px;
  word-break: keep-all;
}

.CareList .list3 span {
  font-size: 14px;
  font-weight: bold;

  color: #2679b2;
  margin-left: 3%;
  margin-right: 3%;
}

.CareList .list3 .sub {
  margin-left: 13%;
  font-size: 13px;

  font-family: "koPubWorld DotumM";
  color: #333333;
  line-height: 20px;
  margin-bottom: 2%;
  word-break: keep-all;
}

.CareList .list3 .sub span {
  font-size: 10px;
  font-weight: 0;
  margin-right: 5%;
  color: #333333;
}

.CareList table {
  width: 100%;
  height: 120px;
  border-top: #e5857c 2px solid;
  margin-top: 5%;
  margin-bottom: 3%;
}
.CareList table thead {
  background-color: #f4f4f4;
}

.CareList table thead tr th {
  font-size: 13px;
  line-height: 35px;
  font-family: "koPubWorld DotumB";
  border: #c1c1c1 1px solid;
  border-top: none;
}

.CareList table tbody {
  background-color: #fff;
}

.CareList table tbody tr td {
  font-size: 13px;
  font-family: "koPubWorld DotumM";
  line-height: 20px;
  text-align: center;
  border: #c1c1c1 1px solid;
  border-top: none;
}

.CareList .list3 div p {
  color: #333333;
  text-indent: -1.5em;
  margin-left: 1em;
  font-family: "koPubWorld DotumM";
  line-height: 22px;
}

@media (min-width: 266px) {
  .CareList .name {
    background-size: 13px 13px;

    font-size: 15px;
  }
  .CareList .list1 .list2 p {
    font-size: 12px;
  }
  .CareList .list3 p {
    font-size: 12px;
    text-indent: -2em;
    margin-left: 2.3em;
    line-height: 22px;
  }

  .CareList .list3 .sub {
    margin-left: 15%;
    font-size: 11px;
    margin-bottom: 2%;
  }

  .CareList .list3 .sub span {
    font-size: 10px;
    font-weight: 0;
    margin-right: 5%;
  }
}

@media (min-width: 320px) {
  .CareList .name {
    background-size: 15px 15px;

    font-size: 17px;
  }
  .CareList .list1 .list2 p {
    font-size: 13px;
  }
  .CareList .list3 p {
    font-size: 13px;
    text-indent: -2.2em;
    margin-left: 2.3em;
    line-height: 22px;
  }

  .CareList .list3 .sub {
    margin-left: 15%;
    font-size: 12px;
    margin-bottom: 2%;
  }

  .CareList .list3 .sub span {
    font-size: 10px;
    font-weight: 0;
    margin-right: 5%;
  }
}
@media (min-width: 375px) {
  .CareList .name {
    background-size: 17px 17px;
    font-size: 19px;
  }
  .CareList .list3 p {
    font-size: 14px;
    text-indent: -2.3em;
    margin-left: 2.3em;
    line-height: 24px;
  }

  .CareList .list1 .list2 p {
    font-size: 14px;
  }

  .CareList .list3 .sub {
    margin-left: 13%;
    font-size: 13px;

    font-family: "koPubWorld DotumM";
    color: #333333;
    line-height: 20px;
    margin-bottom: 2%;
  }

  .CareList .list3 .sub span {
    font-size: 10px;
    font-weight: 0;
    margin-right: 5%;
  }
}

@media (min-width: 425px) {
}

@media (min-width: 768px) {
  .CareList .name {
    background-size: 24px 24px;
    font-size: 26px;
  }
  .CareList .list3 p {
    font-size: 18px;
    text-indent: -2.9em;
    margin-left: 2.9em;
    line-height: 27px;
  }

  .CareList .list1 .list2 p {
    font-size: 18px;
  }

  .CareList .list3 .sub {
    margin-left: 15%;
    text-indent: -14%;

    font-size: 16px;

    line-height: 24px;
    margin-bottom: 2%;
  }

  .CareList .list3 .sub span {
    font-size: 10px;
    font-weight: 0;
    margin-right: 3%;
  }

  .CareList table {
    height: 200px;
  }

  .CareList table thead tr th {
    font-size: 20px;
    line-height: 50px;
  }

  .CareList table tbody tr td {
    font-size: 20px;
    line-height: 30px;
  }
}
