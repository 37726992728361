.Intro .depth2 {
  width: 100%;
  margin: 0;
}

.Intro .depth2 li {
  text-align: center;
  float: left;
  width: 20%;
  height: 40px;
  padding-left: 2%;
  margin-left: 0;
  padding-right: 2%;
  line-height: 40px;
  background-color: #f8f8f8;
  margin-right: 1%;
  border-bottom: 2px solid #cecece;
}

.Intro .depth2 li a {
  text-decoration: none;
  font-size: 13px;
  color: #666666;
  font-family: "Nanum Square Bold";
}

.Intro .depth2 li:last-child {
  margin-right: 0;
  width: 21%;
}

.Intro .subtitle {
  margin-top: 17%;
  font-family: "Nanum Square Bold";
  color: #555555;
  font-size: 35px;
  line-height: 1em;
  padding-left: 5%;
}

.Intro .one {
  position: relative;
  width: 90%;
  margin-top: 10%;
  height: 400px;
  margin-left: 5%;
  margin-right: 5%;
  background: 45% center no-repeat;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(../../assets/main_logo.png);
  background-size: 150px 150px;
}

.Intro .one h1 {
  font-size: 18px;
  font-family: "koPubWorld DotumB";
  color: #333333;
  margin-bottom: 20px;
}

.Intro .two {
  width: 100%;
}

.Intro .two p {
  font-size: 15px;
  font-family: "koPubWorld DotumM";
  color: #333333;
}

.Intro .two .p2 {
  margin-top: 5%;
}
.Intro .three {
  width: 90%;
  margin-top: 30px;
}
.Intro .three p {
  font-family: "koPubWorld DotumB";
  color: #2679b2;
  font-size: 17px;
}

.Intro .orga {
  width: 90%;
  height: 300px;
  margin: auto;
  margin-top: 50px;
  background: #fcfcfc url("../assets/orga.png") no-repeat center center;

  background-size: 100% 300px;
}

.Intro.list {
  float: left;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.Intro .list li {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.Intro .list2 h1 {
  background: url("../../assets/list.png") left no-repeat;
  background-size: 20px 20px;
  color: #e5857c;
  font-size: 20px;
  padding-left: 8%;
  margin-bottom: 5%;
  font-family: "Nanum Gothic Bold";
}
.Intro .list2 li {
  margin-top: 1%;
  color: #1976d2;

  font-family: "koPubWorld DotumB";
}

.Intro .list2 .addr {
  color: #333333;
}
.Intro .list3 {
  list-style: disc;
  color: #333333;
  margin-left: 7%;
  margin-right: 5%;
  font-family: "koPubWorld DotumB";
  word-break: keep-all;
}

.Intro .list3 li {
  color: #333333;
}

.Intro .list3 ol li {
  padding: 0;
  margin-left: 0;
}

.Intro .list3 p {
  font-size: 16px;
  padding-left: 1%;
  color: #333333;
}
@media (max-width: 320px) {
  .History {
    height: 890px;
  }
  .Intro .one h1 {
    font-size: 16px;
  }
  .Intro .one {
    width: 95%;
  }

  .Intro .subtitle {
    margin-top: 25%;
    font-size: 30px;
  }

  .Intro .list li {
    font-size: 12px;
  }
  .Intro .list3 p {
    font-size: 14px;
  }

  .Intro .list2 .addr {
    margin-left: 7%;
  }

  .Intro .two p {
    font-size: 11px;
  }

  .Intro .three p {
    font-size: 12px;
  }
  .Intro .list2 h1 {
    background-size: 17px 17px;
    font-size: 17px;
    padding-left: 10%;
  }
}
@media (min-width: 320px) {
  .Intro .list2 h1 {
    background-size: 20px 20px;

    font-size: 20px;
    padding-left: 8%;
  }
  .Intro .one {
    width: 90%;
  }
  .Intro .subtitle {
    margin-top: 17%;
    font-size: 35px;
  }
  .Intro .one h1 {
    font-size: 18px;
  }
  .History {
    height: 890px;
  }
  .Intro .orga {
    width: 90%;
    height: 280px;
    background-size: 100% 280px;
  }
  .Intro .list li {
    font-size: 14px;
  }
  .Intro .list3 p {
    font-size: 15px;
  }

  .Intro .list2 .addr {
    margin-left: 5%;
  }

  .Intro .two p {
    font-size: 13px;
  }

  .Intro .three p {
    font-size: 15px;
  }
}
@media (min-width: 375px) {
  .Intro .orga {
    height: 300px;

    background-size: 100% 300px;
  }

  .Intro .list li {
    font-size: 16px;
  }
  .Intro .list3 p {
    font-size: 16px;
  }
  .Intro .two p {
    font-size: 15px;
  }
  .Intro .three p {
    font-size: 17px;
  }
}

@media (min-width: 425px) {
  .Intro .orga {
    height: 300px;

    background-size: 100% 300px;
  }
  .Intro .two p {
    font-size: 17px;
  }
  .Intro .three p {
    font-size: 19px;
  }
}

@media (min-width: 768px) {
  .Intro .orga {
    height: 400px;

    background-size: 100% 400px;
  }

  .Intro .list2 h1 {
    background: url("../../assets/list.png") 3% no-repeat;
    background-size: 24px 24px;
    font-size: 24px;
  }

  .Intro .list li {
    font-size: 18px;
  }
  .Intro .list2 .addr {
    margin-left: 7%;
  }
  .Intro .list3 p {
    font-size: 20px;
  }
  .Intro .one {
    background-size: 250px 250px;
  }

  .Intro .two p {
    font-size: 25px;
  }

  .Intro .one h1 {
    font-size: 32px;
  }

  .Intro .subtitle {
    font-size: 40px;
  }
  .Intro .three p {
    font-size: 28px;
  }
}
