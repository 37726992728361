.state {
  font-size: 20px;
  float: left;
  color: #333333;
  line-height: 33px;
  font-family: "koPubWorld DotumB";
}
.search {
  float: right;
  margin-right: 30px;
  color: #333333;
  font-family: "koPubWorld DotumB";
}

.search select {
  width: 80px;
  height: auto;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff url(../assets/arrow.png) no-repeat 55px 10px;

  margin-right: 10px;
}

.search input {
  width: 200px;
  height: auto;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 4px;
  padding-left: 10px;
  margin-right: 10px;
}
.search .hidden {
  display: none;
}

.search img {
  width: 30px;
}

.table {
  margin-top: 20px;
  float: left;
  min-width: 850px;
  width: 98%;
}

.table .colum {
  border-top: 1px solid #e5857c;
  border-bottom: 3px solid #e5857c;
  padding-top: 17px;
  padding-bottom: 17px;
}
.table .colum li {
  float: left;
  text-align: center;
  font-size: 20px;
  border-right: 1px solid #828282;
  font-family: "koPubWorld DotumM";
  color: #333333;
}
.table .colum li:nth-child(1) {
  min-width: 100px;
  width: 9%;
  max-width: 200px;
}
.table .colum li:nth-child(2) {
  min-width: 420px;
  width: 49%;
}
.table .colum li:nth-child(3) {
  min-width: 150px;
  width: 18%;
}
.table .colum li:nth-child(4) {
  min-width: 150px;
  width: 18%;
  border-right: 0;
}

.table li .ItemListItem {
  width: 100%;
  text-align: center;
  float: left;
  color: #333333;

  border-bottom: 1px solid #c8c8c8;
}

.table li .ItemListItem p {
  float: left;
  font-size: 20px;
  font-family: "koPubWorld DotumB";

  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.table li .ItemListItem p a {
  font-size: 20px;

  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "koPubWorld DotumB";
  color: #333333;
}

.table li .ItemListItem p:nth-child(1) {
  min-width: 100px;
  width: 9%;
  max-width: 200px;
}
.table li .ItemListItem p:nth-child(2) {
  text-align: left;

  min-width: 420px;
  width: 49%;
}
.table li .ItemListItem p:nth-child(3) {
  min-width: 150px;
  width: 18%;
}
.table li .ItemListItem p:nth-child(4) {
  min-width: 150px;
  width: 18%;
  border-right: 0;
}

.important {
  background: url("../assets/notice.png") center center no-repeat;
  background-size: 65px 35px;
  font-family: "koPubWorld DotumB";
  color: #fff;
}
