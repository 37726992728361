.ItemList {
  width: 90%;
  float: left;
  margin-left: 5%;
  margin-top: 5%;
}

.search {
  float: left;
  width: 100%;
  color: #333333;
  font-family: "koPubWorld DotumB";
}
.search select {
  font-family: "koPubWorld DotumB";
  width: 20%;
  height: auto;
  text-align: left;
  font-size: 15px;
  padding-top: 1.3%;
  padding-bottom: 1.3%;
  padding-right: 2%;
  padding-left: 2%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff url(../../assets/arrow.png) no-repeat 90% 50%;

  margin-right: 2.5%;
}

.search input {
  width: 60%;
  height: auto;
  font-size: 14px;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 2%;
  margin-right: 3%;
}
.search .hidden {
  display: none;
}

.search img {
  width: 34px;
}

.table {
  margin-top: 5%;
  float: left;
  width: 100%;
  border-top: 2px solid #e5857c;
}

.table li .ItemListItem {
  width: 100%;
  text-align: center;
  float: left;
  color: #333333;

  border-bottom: 1px solid #c8c8c8;
}

.table li .ItemListItem p {
  float: left;
  font-size: 15px;
  font-family: "koPubWorld DotumB";
  padding-bottom: 3%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.table li .ItemListItem p a {
  font-size: 17px;

  font-family: "koPubWorld DotumB";
  color: #333333;
}

.table li .ItemListItem p:nth-child(1) {
  width: 13%;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-top: 6%;
  margin-bottom: 3%;
  margin-left: 4%;
}

.table li .ItemListItem p:nth-child(1):not(.important) {
  font-family: "koPubWorld DotumM";
}

.table li .ItemListItem p:nth-child(2) {
  text-align: left;
  width: 80%;
  padding-bottom: 0%;
  padding-top: 3%;
}
.table li .ItemListItem p:nth-child(3) {
  width: 15.5%;
  font-size: 13px;
  text-align: right;
  color: #666666;
}
.table li .ItemListItem p:nth-child(4) {
  width: 20%;
  font-size: 13px;
  text-align: center;
  font-family: "koPubWorld DotumM";
  color: #666666;
}

.table li .ItemListItem p:nth-child(5) {
  width: 18%;
  font-size: 13px;
  text-align: right;
  color: #666666;
}

.table li .ItemListItem p:nth-child(6) {
  width: 28%;
  font-size: 13px;
  color: #666666;
  text-align: center;
  font-family: "koPubWorld DotumM";
}

.important {
  background: url("../../assets/notice.png") center center no-repeat;
  background-size: 40px 27px;
  font-family: "koPubWorld DotumB";
  color: #fff;
}

.ItemListItem img {
  width: 6%;
}

.table li .ImageListItem {
  width: 47%;
  text-align: center;
  float: left;
  color: #333333;
  padding-bottom: 5%;
  border-bottom: solid 1px #c8c8c8;
}

.table li:nth-child(odd) .ImageListItem {
  padding-right: 6%;
}

.table li .ImageListItem .image {
  padding-top: 75%;
  margin-top: 30px;
  margin-bottom: 15px;
  float: left;
  width: 100%;

  background: #f4f4f4 url(../../assets/logo_preview.png) 50% 50% no-repeat;
  background-size: 80% 70%;
}

.table li .ImageListItem .image2 {
  margin-top: 30px;
  margin-bottom: 15px;
  float: left;
  width: 100%;
  padding-top: 75%;
}

.table li .ImageListItem p {
  font-size: 17px;
  float: left;
  color: #333333;
  text-align: left;
}

.table li .ImageListItem p:nth-child(2) {
  width: 100%;
  font-family: "koPubWorld DotumB";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.table li .ImageListItem p:nth-child(3) {
  width: 63%;
  font-size: 12px;
  font-family: "koPubWorld DotumM";
}
.table li .ImageListItem p:nth-child(4) {
  text-align: right;
  width: 37%;
  font-size: 12px;
  font-family: "koPubWorld DotumM";
}
@media (max-width: 320px) {
  .search img {
    width: 25px;
  }
  .search select {
    width: 20%;
    font-size: 12px;
    padding-top: 1.1%;
    padding-bottom: 1.1%;
  }

  .search input {
    width: 59%;
    font-size: 12px;
  }
  .important {
    background-size: 32px 22px;
  }
  .table li .ItemListItem p {
    font-size: 12px;
  }

  .table li .ItemListItem p a {
    font-size: 14px;
  }

  .table li .ItemListItem p:nth-child(3) {
    width: 18%;
    font-size: 11px;
  }
  .table li .ItemListItem p:nth-child(4) {
    font-size: 11px;
    width: 17%;
  }

  .table li .ItemListItem p:nth-child(5) {
    font-size: 11px;
  }

  .table li .ItemListItem p:nth-child(6) {
    font-size: 11px;
  }
  .table li .ImageListItem p {
    font-size: 14px;
  }

  .table li .ImageListItem p:nth-child(3) {
    width: 69%;
    font-size: 10px;
  }
  .table li .ImageListItem p:nth-child(4) {
    font-size: 10px;
    width: 31%;
  }
}

@media (min-width: 320px) {
  .search img {
    width: 30px;
  }
  .search select {
    width: 20%;
    font-size: 14px;
    padding-top: 1.1%;
    padding-bottom: 1.1%;
  }

  .search input {
    width: 60%;
    height: auto;
    font-size: 13px;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    margin-right: 3%;
  }
  .important {
    background-size: 36px 24px;
  }
  .table li .ItemListItem p {
    font-size: 13px;
  }

  .table li .ItemListItem p a {
    font-size: 15px;
  }

  .table li .ItemListItem p:nth-child(3) {
    width: 16%;
    font-size: 12px;
  }
  .table li .ItemListItem p:nth-child(4) {
    font-size: 12px;
  }

  .table li .ItemListItem p:nth-child(5) {
    font-size: 12px;
  }

  .table li .ItemListItem p:nth-child(6) {
    font-size: 12px;
  }
  .table li .ImageListItem p {
    font-size: 15px;
  }

  .table li .ImageListItem p:nth-child(3) {
    width: 63%;
    font-size: 11px;
  }
  .table li .ImageListItem p:nth-child(4) {
    font-size: 11px;
    width: 37%;
  }
}
@media (min-width: 375px) {
  .search img {
    width: 34px;
  }
  .search select {
    width: 20%;
    font-size: 15px;
    padding-top: 1.3%;
    padding-bottom: 1.3%;
  }

  .search input {
    width: 60%;
    height: auto;
    font-size: 14px;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    margin-right: 3%;
  }
  .important {
    background-size: 40px 27px;
  }

  .table li .ItemListItem p {
    font-size: 15px;
  }

  .table li .ItemListItem p a {
    font-size: 17px;
  }

  .table li .ItemListItem p:nth-child(3) {
    width: 15.5%;
    font-size: 13px;
  }
  .table li .ItemListItem p:nth-child(4) {
    font-size: 13px;
  }

  .table li .ItemListItem p:nth-child(5) {
    font-size: 13px;
  }

  .table li .ItemListItem p:nth-child(6) {
    font-size: 13px;
  }
  .table li .ImageListItem p {
    font-size: 16px;
  }

  .table li .ImageListItem p:nth-child(3) {
    font-size: 12px;
  }
  .table li .ImageListItem p:nth-child(4) {
    font-size: 12px;
  }
}

@media (min-width: 425px) {
  .table li .ItemListItem p:nth-child(3) {
    width: 13.5%;
  }
  .table li .ImageListItem p {
    font-size: 17px;
  }

  .table li .ImageListItem p:nth-child(3) {
    font-size: 13px;
  }
  .table li .ImageListItem p:nth-child(4) {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .important {
    background-size: 46px 36px;
  }
  .table li .ItemListItem p {
    font-size: 17px;
  }

  .table li .ItemListItem p a {
    font-size: 20px;
  }

  .table li .ItemListItem p:nth-child(3) {
    width: 9.5%;
    font-size: 17px;
  }
  .table li .ItemListItem p:nth-child(4) {
    font-size: 17px;
  }

  .table li .ItemListItem p:nth-child(5) {
    font-size: 17px;
  }

  .table li .ItemListItem p:nth-child(6) {
    font-size: 17px;
  }

  .table li .ImageListItem p {
    font-size: 15px;
  }

  .table li .ImageListItem p:nth-child(3) {
    font-size: 11px;
  }
  .table li .ImageListItem p:nth-child(4) {
    font-size: 11px;
  }
}
