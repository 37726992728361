.Main {
  float: left;
  width: 100%;
  height: 1000px;

  background-color: #fcf5f4;
}

.Main .image {
  width: 80%;
  margin: auto;
  height: 230px;

  background: url("../assets/Main.png") center center no-repeat;
  background-size: 100% 200px;
  margin-bottom: 20px;
}

.sub {
  margin: auto;
  width: 70%;
  text-align: center;
  font-size: 25px;
  font-family: "MapoPeacefull";
  color: #323332;
}

.sub2 {
  margin: auto;
  width: 70%;
  text-align: center;
  font-size: 25px;
  line-height: 40px;
  font-family: "MapoPeacefull";
  color: #323332;
}

.sub2 .red {
  font-size: 25px;
  display: inline;
  color: #2679b2;
}

.sub2 .blue {
  color: #d94a3c;
  font-size: 25px;
  display: inline;
}

/*바로가기 메뉴*/
#ctn2_quick {
  margin-top: 20px;

  position: relative;
  float: left;
  width: 100%;
  height: 440px;
  background-color: #fff;
  font-family: "Nanum Gothic Bold";
}
.ctn_title {
  float: left;
  width: 90%;
  font-size: 20px;
  margin-top: 21px;
  padding-left: 8%;
  font-weight: bold;
  margin-right: 30px;
  color: #e5857c;
  font-family: "Nanum Gothic Bold";
}
.ctn2_txt {
  font-size: 16px;
  color: #333333;
  padding-top: 50px;
  line-height: 24px;
  margin-left: 8%;

  font-family: "koPubWorld DotumB";
}

.care_list {
  position: relative;
  top: 16px;
  margin: auto;
}
.care_list li {
  float: left;
  width: 40%;
  padding: 0;
  margin: auto;
}
.care_list li.sv1 {
  background: url(../assets/sv1.png) no-repeat center;
  background-size: 100% 100%;
  margin-left: 8%;
  margin-right: 5%;
  margin-bottom: 5%;
}
.care_list li.sv2 {
  background: url(../assets/sv2.png) no-repeat center;
  background-size: 100% 100%;
  margin-bottom: 5%;
}
.care_list li.sv3 {
  background: url(../assets/sv3.png) no-repeat center;
  background-size: 100% 100%;
  margin-left: 8%;
  margin-right: 5%;
}
.care_list li.sv4 {
  background: url(../assets/sv4.png) no-repeat center;
  background-size: 100% 100%;
}

.care_list li a {
  display: block;
  width: 150px;
  height: 152px;
  color: #323232;
}

/* 서비스 안내 */
#ctn2_tel {
  position: relative;
  float: left;
  width: 100%;
  height: 170px;
  background-color: #fcf5f4;

  font-family: "Nanum Gothic Bold";
}
.ctn_title2 {
  width: 107px;
  font-size: 20px;
  margin-top: 21px;
  padding-left: 6%;
  color: #e5857c;
  font-family: "Nanum Gothic Bold";
}
.ctn3_txt {
  font-size: 15px;
  color: #212121;
  padding-left: 6%;
  margin-top: 7px;
  font-family: "koPubWorld DotumB";
}
.ctn3_tel1 {
  font-size: 37px;
  font-family: "Nanum Gothic Bold";
  font-weight: bold;
  color: #ea4336;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 21%;

  background: url(../../assets/icon_tel.png) no-repeat 13% 50%;
  background-size: 10% 80%;
}
.ctn3_tel2 {
  padding-left: 6%;
  font-size: 13px;
  font-family: "koPubWorld DotumB";
}

#Tabbox2 {
  position: relative;
  width: 100%;
  background-color: #fff;
  float: left;
}

#Tabbox2 .title {
  font-size: 18px;
  width: 90%;
  height: 50px;
  color: #333333;
  line-height: 55px;
  padding-left: 5%;
  margin: auto;
  border-bottom: #c1c1c1 solid 1px;
  margin-bottom: 10px;
  font-family: "Nanum Gothic Bold";
}

@media (max-width: 320px) {
  .Main {
    height: 1480px;
  }
  .Main .image {
    height: 180px;
    background-size: 100% 150px;
    margin-bottom: 10px;
  }

  .sub {
    font-size: 18px;
  }

  .sub2 {
    font-size: 18px;
  }

  .sub2 .red {
    font-size: 18px;
  }

  .sub2 .blue {
    font-size: 18px;
  }
  .ctn_title2 {
    font-size: 16px;
  }
  .ctn3_tel1 {
    font-size: 27px;
  }
  .ctn3_txt {
    font-size: 12px;
  }
  .ctn3_tel2 {
    font-size: 13px;
  }
  .ctn_title {
    font-size: 18px;
  }
  .ctn2_txt {
    font-size: 14px;
  }
  .care_list li.sv1 {
    background-size: 100% 80%;
  }
  .care_list li.sv2 {
    background-size: 100% 80%;
  }
  .care_list li.sv3 {
    background-size: 100% 80%;
  }
  .care_list li.sv4 {
    background-size: 100% 80%;
  }
}

@media (min-width: 320px) {
  .Main {
    height: 1528px;
  }
  .Main .image {
    height: 180px;
    background-size: 100% 150px;
    margin-bottom: 10px;
  }
  .ctn_title {
    font-size: 20px;
  }

  .sub {
    font-size: 21px;
  }

  .sub2 {
    font-size: 21px;
  }

  .sub2 .red {
    font-size: 21px;
  }

  .sub2 .blue {
    font-size: 21px;
  }
  .ctn_title2 {
    font-size: 18px;
  }
  .ctn3_tel1 {
    font-size: 30px;
  }
  .ctn3_txt {
    font-size: 13px;
  }
  .ctn3_tel2 {
    font-size: 11px;
  }
  .ctn2_txt {
    font-size: 16px;
  }
  .care_list li.sv1 {
    background-size: 100% 100%;
  }
  .care_list li.sv2 {
    background-size: 100% 100%;
  }
  .care_list li.sv3 {
    background-size: 100% 100%;
  }
  .care_list li.sv4 {
    background-size: 100% 100%;
  }
}
@media (min-width: 375px) {
  .Main {
    height: 1539px;
  }
  .ctn_title2 {
    font-size: 20px;
  }
  .ctn3_tel1 {
    font-size: 37px;
  }
  .ctn3_txt {
    font-size: 15px;
  }
  .ctn3_tel2 {
    font-size: 13px;
  }
  #ctn2_tel {
    height: 180px;
  }
}

@media (min-width: 425px) {
  .ctn_title2 {
    font-size: 21px;
  }
  .ctn3_tel1 {
    font-size: 38px;
  }
  .ctn3_txt {
    font-size: 17x;
  }
  .ctn3_tel2 {
    font-size: 14px;
  }
  #ctn2_tel {
    height: 190px;
  }
  .Main {
    height: 1548px;
  }
}

@media (min-width: 768px) {
  .Main {
    height: 2374px;
  }
  .Main .image {
    height: 300px;
    background-size: 100% 280px;
    margin-bottom: 30px;
  }

  .sub {
    font-size: 40px;
  }

  .sub2 {
    font-size: 40px;
    line-height: 70px;
  }

  .sub2 .red {
    font-size: 40px;
  }

  .sub2 .blue {
    font-size: 40px;
  }

  /*바로가기 메뉴*/

  .ctn_title {
    font-size: 28px;
    margin-top: 21px;
  }
  .ctn2_txt {
    font-size: 20px;
    padding-top: 70px;
    line-height: 24px;
  }

  #ctn2_quick {
    height: 710px;
  }
  .care_list li {
    height: 260px;
  }

  .ctn_title2 {
    font-size: 22px;
  }
  .ctn3_tel1 {
    font-size: 58px;
  }
  .ctn3_txt {
    font-size: 17px;
  }
  .ctn3_tel2 {
    font-size: 17px;
  }
  #ctn2_tel {
    height: 220px;
  }

  #Tabbox2 .title {
    font-size: 30px;
    height: 70px;
    line-height: 75px;
    margin-bottom: 30px;
  }
}
