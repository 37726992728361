.Nav {
  min-width: 1020px;
  max-width: 100%;
}
.Nav:after {
  display: block;
  clear: both;
  content: "";
}
@font-face {
  font-family: "koPubWorld DotumM";
  src: url("../font/KoPubWorld\ Dotum\ Medium.ttf");
}

@font-face {
  font-family: "koPubWorld DotumB";
  src: url("../font/KoPubWorld\ Dotum\ Bold.ttf");
}

@font-face {
  font-family: "MapoPeacefull";
  src: url("../font/MapoPeacefull.ttf");
}

@font-face {
  font-family: "Nanum Gothic Bold";
  src: url("../font/NanumGothicBold.ttf");
}

@font-face {
  font-family: "Nanum Square Bold";
  src: url("../font/NanumSquareB.ttf");
}
